import React from "react";
import IframeResizer from "iframe-resizer-react";
import loadable from "@loadable/component";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignIndex() {
  return (
    <div className="design__content">
      <SEO title="Quilt: The Buffy Design System" />
      <h2 className="design__title">Quilt: The Buffy Design System</h2>
      <p className="design__text p2">
        Quilt, the Buffy Design System, is a reusable component library that
        helps engineers build UIs faster and closer to overall brand guidelines.
        It also offers an opportunity for designers and other digital product
        contributers an opportunity to stay up to date on the look of our
        digital experiences. The goal is to have one unified source of truth for
        all digital UI components for rapid development of new features.
      </p>
      <h4>It&apos;s Built On A Grid</h4>
      <p className="design__text p2">
        The Engineering Grid layout system, Lattice, was created to provide a
        framework that supports all of the digital designs of the Creative Team.
        It is a 12-column layout page design that acts as a skeleton to create
        the many variations of pages and experiences that Buffy provide to
        customers.
      </p>
      <p className="design__text p2">
        Here&apos;s a demo of the type of page we&apos;re building, with grid
        lines to show how our goal is for content to fit neatly within the grid:
      </p>

      <IframeResizer
        heightCalculationMethod="documentElementScroll"
        src="/demo/grid/"
        className="design__iframe"
        title="Demo"
      />
      <p className="design__text p2">
        Lattice is maintained on{" "}
        <a
          href="https://github.com/buffyco/lattice"
          target="_blank"
          rel="noopener noreferrer">
          Github
        </a>
        .
      </p>
      <h4>Rapid Development</h4>
      <p className="design__text p2">
        We also use Gatsby to build our web experiences. Gatsby is a tool to
        create static website and applications that are meant for speed and
        performance. Buffy has a Gatsby starter site that you can get setup in
        minutes. In your terminal, just type:
      </p>
      <CodeBlock language="text">
        gatsby new new-buffy-site
        https://github.com/buffyco/buffy-gatsby-starter-app.git
      </CodeBlock>
      <p className="design__text p2">
        This will install all the libraries and packages needed to get started
        on a new app. For more info, head to the{" "}
        <a
          href="https://github.com/buffyco/buffy-gatsby-starter-app"
          target="_blank"
          rel="noopener noreferrer">
          starter app repository
        </a>
        .
      </p>
    </div>
  );
}

export default DesignIndex;
